import { isEmpty } from 'lodash';
import './style.scss';
import { useTypedSelector } from 'src/store/hooks';
import { MyCartState, Product } from 'src/store';
import { useEffect, useState } from 'react';
import cardIcon from 'src/assets/cardIcon.svg';
import addCardIcon from 'src/assets/addCard.svg';
import { CostInfoProps } from '../mycart.interface';
import { getQuotation } from 'src/lib/order/orderData';
import { getUserCardList } from 'src/lib/payment/payment';
import { useHistory } from 'react-router-dom';
import { FaMoneyCheck, FaShoppingBag, FaTag } from 'react-icons/fa';
import { CostInfo } from '../cost-info/cost-info';
import { calculateDiscountAmount } from 'src/lib/utils/utils';

interface CheckOutProcessStep {
  setp: number;
  stepLable: string;
}
interface PaymentInfoProps {
  business: Record<string, any>;
  businessDetails: Record<string, any>;
  deliveryAddress: Record<string, any>;
  billingAddress: Record<string, any>;
  getOrderCost: (v: CostInfoProps) => void;
  getPaymentMethod: (v: Record<string, any>) => void;
  getAppliedCoupon: (v: Record<string, any>) => void;
  getpaymentType: (v: string) => void;
  // onWalletPayment: (v: Record<string, any>) => void;
  orderCost: CostInfoProps;
  checkOutProcessStep: CheckOutProcessStep;
  appliedCoupon: Record<string, any>;
  contactNumber: string;
  coupons?: any[];
}

export const PaymentInformation = ({ deliveryAddress, billingAddress, ...props }: PaymentInfoProps) => {
  const history = useHistory();
  const [isCardListLoading, setIsCardListLoading] = useState(false);
  const [userCardList, setUserCardList] = useState<any>([]);
  const [couponCode, setCouponCode] = useState<string>('');
  const myCartState: MyCartState = useTypedSelector((state) => state.order);
  const items = isEmpty(myCartState.collection) ? [] : myCartState.collection.items;
  const [couponCodeError, setCouponCodeError] = useState('');
  const [couponCodeSuccess, setCouponCodeSuccess] = useState('');
  const [paymentsMethod, setPaymentMethod] = useState('');
  const [discountAmount, setDiscountAmount] = useState('');
  const [pmId, setPmId] = useState<string>('');
  useEffect(() => {
    checkBrowser();
    getCardList();
    autoApplyCoupon();
    if (props.appliedCoupon) {
      const { voucherCode } = props.appliedCoupon;
      setCouponCode(voucherCode);
      applyCoupon(props.appliedCoupon);
    }
  }, []);

  const autoApplyCoupon = () => {
    props?.coupons!.map((item: any) => {
      const customData = item?.customData;
      if (customData && customData.minAmount && customData.maxAmount) {
        if (getOrderTotal() >= customData.minAmount && getOrderTotal() <= customData.maxAmount) {
          setCouponCode(item.code);
          applyCoupon({
            type: 'coupon',
            voucherCode: item.code,
          });
        }
      }
    });
  };

  function checkBrowser() {
    // Get the user-agent string
    const userAgentString = navigator.userAgent;
    // Detect Chrome
    const chromeAgent = userAgentString.indexOf('Chrome') > -1;
    // Detect Safari
    let safariAgent = userAgentString.indexOf('Safari') > -1;
    // Discard Safari since it also matches Chrome
    if (chromeAgent && safariAgent) safariAgent = false;
  }

  const applyCoupon = async (discount?: Record<string, any>) => {
    const addressData = {
      phoneNumber: deliveryAddress?.phoneNumber,
      addressLine1: deliveryAddress?.fullAddress,
      addressLine2: deliveryAddress?.fullAddress,
      city: deliveryAddress?.city,
      state: deliveryAddress?.state,
      latitude: deliveryAddress?.latitude ? deliveryAddress.latitude : '',
      longitude: deliveryAddress?.longitude ? deliveryAddress.longitude : '',
      pin: deliveryAddress?.zip ? deliveryAddress?.zip : deliveryAddress?.pin,
    };
    let userOrder = {};
    userOrder = { address: { dropAddress: addressData }, payment: { method: 'CARD' } };
    if (!isEmpty(discount)) {
      userOrder = {
        address: { dropAddress: addressData },
        payment: { method: 'CARD' },
        discount,
      };
    }
    if (!isEmpty(props.businessDetails)) {
      userOrder = { ...userOrder, event: { businessDetails: props.businessDetails } };
    }
    const reqData = {
      items: items,
      order: userOrder,
    };

    const response = await getQuotation(reqData);
    if (response.status == 1) {
      if (response.result.order.actualAmount != 0) {
        const quotationResponse = response.result.order;
        if (quotationResponse.discount && quotationResponse.discount?.discountId) {
          props.getAppliedCoupon({
            type: 'coupon',
            voucherCode: couponCode || quotationResponse.discount.voucherCode,
          });
          localStorage.setItem(
            'coupon',
            JSON.stringify({
              type: 'coupon',
              voucherCode: couponCode || quotationResponse.discount.voucherCode,
            }),
          );
          const amount = (quotationResponse.discount?.percentage / 100) * getOrderTotal();
          setDiscountAmount('$' + amount.toFixed(2));
          const costInfo: CostInfoProps = {
            orderSubTotal: { label: 'Order Total', value: quotationResponse.actualAmountLabel },
            orderTotal: { label: 'Total', value: quotationResponse.totalAmountLabel },
            totalAddOnCost: { label: 'Taxes', value: quotationResponse.totalAddOnCost.taxesLabel },
            shippingTotal: { label: 'Delivery Fee', value: quotationResponse.totalAddOnCost.shippingLabel },
            couponDiscountTotal: {
              label: `Coupon (${quotationResponse.discount.voucherCode})`,
              value: '-$' + amount.toFixed(2),
            },
          };
          props.getOrderCost(costInfo);
          setCouponCodeError('');
          setCouponCodeSuccess('🎉 Congratulations, coupon code applied!');
        } else {
          localStorage.removeItem('coupon');
          const costInfo: CostInfoProps = {
            orderSubTotal: { label: 'Order Total', value: quotationResponse.actualAmountLabel },
            orderTotal: { label: 'Total', value: quotationResponse.totalAmountLabel },
            totalAddOnCost: { label: 'Taxes', value: quotationResponse.totalAddOnCost.taxesLabel },
            shippingTotal: { label: 'Delivery Fee', value: quotationResponse.totalAddOnCost.shippingLabel },
          };
          props.getOrderCost(costInfo);
          setCouponCodeSuccess('');
          if (!isEmpty(discount)) {
            setCouponCodeError('Sorry, this coupon is not valid.');
          }
        }
      } else {
        setCouponCodeError('Sorry, something went wrong.');
        removeCoupon();
      }
    } else {
      setCouponCodeError('Sorry, something went wrong.');
    }
  };

  const validateCouponCodeandApply = (code: string) => {
    const coupon = props?.coupons!.find((item: any) => item.code === code);
    const customData = coupon?.customData;
    if (customData && customData.minAmount && customData.maxAmount) {
      if (getOrderTotal() >= customData.minAmount && getOrderTotal() <= customData.maxAmount) {
        setCouponCode(coupon.code);
        applyCoupon({
          type: 'coupon',
          voucherCode: coupon.code,
        });
      } else {
        setCouponCodeError('Sorry, this coupon is not valid.');
      }
    } else {
      setCouponCode(code);
      applyCoupon({
        type: 'coupon',
        voucherCode: code,
      });
    }
  };

  const removeCoupon = async () => {
    await applyCoupon();
    setCouponCode('');
    localStorage.removeItem('coupon');
  };

  const getOrderTotal = () => {
    let sum = 0;
    // if (items) {
    for (const num of items) {
      const value = num.quantity * Number(num.price);
      sum = sum + value;
      sum = Math.round(sum * 100) / 100;
    }
    return sum;
  };

  const onPaymentMethodSelection = (type: string) => {
    setPaymentMethod(type);
    props.getpaymentType(type);
    if (type === 'card') {
      props.getPaymentMethod({ type: 'default', payload: pmId });
    } else {
      props.getPaymentMethod({ type: type, payload: null });
    }
  };

  const getCardList = async () => {
    setIsCardListLoading(true);
    const cards = await getUserCardList();
    const cardsArray = cards?.result?.cards || [];
    setUserCardList(cardsArray || []);
    setIsCardListLoading(false);
  };
  const handleChange = (e: any) => {
    setPaymentMethod('card');
    props.getpaymentType('card');
    setPmId(e.target.value);
    props.getPaymentMethod({ type: 'default', payload: e.target.value });
  };

  const addcard = () => {
    const state = {
      billingAddress,
      deliveryAddress,
      business: props.business,
      businessDetails: props.businessDetails,
      orderCost: props.orderCost,
      checkOutProcessStep: props.checkOutProcessStep,
      appliedCoupon: props.appliedCoupon,
      contactNumber: props.contactNumber,
    };
    console.log(state);

    history.push(`/payment/add-card`, state);
  };

  function getDiscountedAmount(item: any): string | undefined {
    const discountValue = item?.discount?.value;
    const discountAmount = calculateDiscountAmount(item?.price, discountValue);
    if (discountAmount) {
      const finalPrice = (item?.price - discountAmount).toFixed(2);
      return finalPrice;
    }
    return undefined;
  }

  return (
    <div className="mt-4">
      <div className="d-flex flex-column mb-2">
        <div className="d-flex justify-content-between ">
          {/* <div>Checkout with</div> */}
          <h5 className="cart__title">
            <FaShoppingBag />
            &nbsp;&nbsp;{' '}
            {items.length === 1 ? `ORDER DETAILS (${items.length} ITEM)` : `ORDER DETAILS (${items.length} ITEMS)`}
          </h5>
        </div>
      </div>
      <div className="card__payment">
        {items?.map((item: Product, index: number) => {
          const itemPrice = Number(item.price) * item.quantity;
          const discountAmount = Number(getDiscountedAmount(item)) * item.quantity;
          return (
            <div className=" d-flex justify-content-between " key={index}>
              <p className="pl-2 item__title">
                {item.quantity} x {item.name}
              </p>
              <>
                {item.discount?.type === 'percentage' && item.discount?.value ? (
                  <p className="item__title">${discountAmount.toFixed(2)}</p>
                ) : (
                  <p className="item__title">${itemPrice.toFixed(2)}</p>
                )}
              </>
            </div>
          );
        })}
      </div>
      <div className=" d-block  d-md-none ">
        <CostInfo {...props.orderCost!} showTitle={false} className="card__payment" />
      </div>
      <div className="mt-4">
        <div className="d-flex flex-column mb-2">
          <div className="d-flex justify-content-between ">
            {/* <div>Promo Code</div> */}
            <h5 className="cart__title">
              <FaTag />
              &nbsp;&nbsp;Promo Code
            </h5>
          </div>
        </div>
        <div className="card__payment">
          <div>
            <div className="input-group mt-3 mb-3 col-12 col-md-8 p-0">
              <input
                onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
                type="text"
                className="form-control"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-sm"
                style={{ textTransform: 'uppercase' }}
                value={couponCode}
                readOnly={couponCodeSuccess ? true : false}
              />
              {couponCodeSuccess ? (
                <div className="input-group-prepend" onClick={() => removeCoupon()}>
                  <span className="input-group-text coupon_remove" id="inputGroup-sizing-sm">
                    Remove
                  </span>
                </div>
              ) : (
                <div className="input-group-prepend" onClick={() => validateCouponCodeandApply(couponCode)}>
                  <span className="input-group-text coupon_remove" id="inputGroup-sizing-sm">
                    Apply
                  </span>
                </div>
              )}
            </div>
            {couponCodeSuccess && (
              <div>
                <span className="coupon_msg">{couponCodeSuccess}</span>
                <br />
                <span className="coupon_sub_msg">
                  You saved additional <span style={{ color: '#b25661', fontWeight: 500 }}>{discountAmount}</span>
                </span>
              </div>
            )}
            {couponCodeError && (
              <div>
                <span style={{ color: '#ff5722' }}>{couponCodeError}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between ">
            {/* <div>Checkout with</div> */}
            <h5 className="cart__title">
              <FaMoneyCheck />
              &nbsp;&nbsp;Checkout with
            </h5>
          </div>
        </div>
        <div className="mt-2">
          <div>
            <label htmlFor={'card'} className="w-100 mt-2 ">
              <div
                className="d-flex flex-row mt-2 mb-2 card__payment_wallet"
                style={{ border: paymentsMethod === 'card' ? '1px solid #b25661' : '' }}
              >
                <div className="mt-2">
                  <input
                    id={'card'}
                    name="savedAddress"
                    type="radio"
                    checked={paymentsMethod === 'card'}
                    style={{ height: 'auto', accentColor: '#b25661' }}
                    onChange={() => onPaymentMethodSelection('card')}
                  />
                </div>
                <div className="pl-3 d-flex flex-column mt-2">
                  <div className={`mb-md-3 mb-2 form-group `}>
                    <label className="mb-3" style={{ color: '#000' }}>
                      <img src={cardIcon} alt="Card" />
                      &nbsp;&nbsp;Pay via card
                    </label>

                    <select
                      id="selectCard"
                      style={{ backgroundImage: 'none', cursor: 'pointer' }}
                      value={pmId}
                      className="minimal form-control"
                      onChange={handleChange}
                    >
                      {isCardListLoading ? (
                        <option value="" style={{ color: '#ccc', cursor: 'pointer' }}>
                          Loading payment cards
                        </option>
                      ) : (
                        <>
                          {' '}
                          <option value="" style={{ color: '#ccc', cursor: 'pointer' }}>
                            Select payment card
                          </option>
                        </>
                      )}
                      {userCardList.map((userCard: any, index: number) => (
                        <option card-type={userCard.brand} key={index} value={userCard.id}>
                          ({userCard.brand.toUpperCase()})&nbsp;XXX-{userCard.last4}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </label>
            <label className="w-100 mt-2 ">
              <div className="d-flex flex-row mt-2 mb-2 card__payment_wallet">
                <div className="pl-3 d-flex flex-column mt-2">
                  <a onClick={addcard}>
                    <label style={{ color: '#000', cursor: 'pointer' }}>
                      <img src={addCardIcon} alt="Card" />
                      &nbsp;&nbsp;Add new Credit/ Debit card
                    </label>
                  </a>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
