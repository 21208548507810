import { CartItems } from 'src/components/my-cart/cart-item/cart-item';
import './cart-page.scss';
import { CostInfo } from 'src/components/my-cart/cost-info/cost-info';
import { FaChevronLeft, FaInfoCircle } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { MyCartState } from 'src/store';
import { useTypedSelector } from 'src/store/hooks';
import { DeliveryInfo } from 'src/components/my-cart/delivery-info/delivery-address';
import { useEffect, useState } from 'react';
import { PaymentInformation } from 'src/components/my-cart/payment/payment-info';
import { CostInfoProps } from 'src/components/my-cart/mycart.interface';
import { AxiosRequestConfig } from 'axios';
import axiosInstance from 'src/utils/interceptor';
import { assignTask, confirmOrder } from 'src/lib/order/orderData';
import { BillingInfo } from 'src/components/my-cart/delivery-info/billing-address';
import { isEmpty } from 'lodash';
import { getMyCartData } from 'src/store/action-creators/orderAction';
import { useDispatch } from 'react-redux';
import { getCoupons } from 'src/lib/coupons/coupons';
import { AvailableOffers } from 'src/components/my-cart/offers/AvailableOffers';

interface CheckOutProcessStep {
  setp: number;
  stepLable: string;
}
export const MyCartPage = () => {
  const dispatch = useDispatch();
  const myCartState: MyCartState = useTypedSelector((state) => state.order);
  const items = isEmpty(myCartState.collection) ? [] : myCartState.collection.items;
  const [deliveryAddress, setDeliveryAddress] = useState<Record<string, any>>();
  const [billingAddress, setBillingAddress] = useState<Record<string, any>>();
  const [contactNumber, setContactNumber] = useState<string>();
  const [orderCost, setOrderCost] = useState<CostInfoProps>();
  const [isErrorOnOrder, setErrorOnOrder] = useState<Record<string, any>>();
  const [checkOutProcessStep, setcheckOutProcessStep] = useState<CheckOutProcessStep>({
    setp: 1,
    stepLable: 'PROCEED TO CHECKOUT',
  });
  const [retailerBusiness, setRetailerBusiness] = useState<Record<string, any>>();
  const [businessDetails, setBusinessDetails] = useState<Record<string, any>>();
  const [paymentType, setPaymentType] = useState('');
  const [paymentMethod, setPaymentMethod] = useState<Record<string, any>>();
  const [appliedCoupon, setAppliedCoupon] = useState<Record<string, any>>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [backBtnText, setBackBtnText] = useState<string>('Back To Event');
  const [coupons, setCoupons] = useState([]);
  const [isOrderProcessing, setIsOrderProcessing] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (myCartState.eventId) {
      handleGetEvent(myCartState.eventId);
    }
    getCouponsCode();
  }, [myCartState.eventId]);

  useEffect(() => {
    const state = location.state;
    if (state) {
      const {
        billingAddress,
        deliveryAddress,
        business,
        businessDetails,
        orderCost,
        checkOutProcessStep,
        appliedCoupon,
        contactNumber,
      } = state;
      setBillingAddress(billingAddress);
      setDeliveryAddress(deliveryAddress);
      setBusinessDetails(businessDetails);
      setRetailerBusiness(business);
      setOrderCost(orderCost);
      setcheckOutProcessStep(checkOutProcessStep);
      setAppliedCoupon(appliedCoupon);
      setContactNumber(contactNumber);
    }
  }, [location.state]);

  window.onbeforeunload = function (e) {
    if (window.location.pathname === '/my-cart') {
      history.push('/my-cart');
    }
  };

  const getCouponsCode = async () => {
    const coupons = await getCoupons();
    if (coupons?.result?.coupons) {
      setCoupons(coupons.result.coupons);
    }
  };

  const handleGetEvent = async (eventId: string) => {
    const url = `${process.env.REACT_APP_API_HOST_URL}/event/${eventId}`;
    try {
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const res = await axiosInstance.get(url, requestConfig);
      if (res && res.data && res.data.result) {
        processEventData(res.data.result);
      }
    } catch (error) {
      throw error;
    }
  };

  const processEventData = (result: any) => {
    setRetailerBusiness(result.business);
    setBusinessDetails(result.businessDetails);
  };

  const backToEvent = () => {
    if (checkOutProcessStep.setp === 2) {
      if (isErrorOnOrder!.errorCode === 400008) {
        setcheckOutProcessStep({ setp: 1, stepLable: 'PROCEED TO CHECKOUT' });
        setBackBtnText('Back To Event');
        setTimeout(() => {
          setErrorOnOrder(undefined);
        });
      }
    } else {
      if (myCartState.eventId) {
        history.push(`events/${myCartState.eventId}`);
      } else {
        history.push(`events/upcoming`);
      }
    }
  };
  const checkOutProcess = (checkOutProcess: CheckOutProcessStep) => {
    if (checkOutProcess.setp === 1) {
      setcheckOutProcessStep({ setp: 2, stepLable: 'CONTINUE TO BILLING' });
    } else if (checkOutProcess.setp === 2) {
      if (!deliveryAddress) {
        setErrorMessage('Please provide your delivery address to proceed.');
      } else {
        setcheckOutProcessStep({ setp: 3, stepLable: 'CONTINUE TO PAYMENT' });
      }
    } else if (checkOutProcess.setp === 3) {
      if (!billingAddress) {
        setErrorMessage('Please provide your billing address to proceed.');
      } else {
        setcheckOutProcessStep({ setp: 4, stepLable: 'CONFIRM AND PAY' });
      }
    } else {
      confirmAndPay();
    }
    setTimeout(() => {
      setErrorMessage(undefined);
    }, 4000);
  };

  const getOrderCost = (v: CostInfoProps) => {
    setOrderCost(v);
  };

  const confirmAndPay = () => {
    // if (paymentType === 'googlePay') {
    //   document.getElementById('google-pay-button')?.click();
    // } else if (paymentType === 'applePay') {
    //   document.getElementById('apple-pay-button')?.click();
    // } else
    if (paymentType === 'card') {
      if (deliveryAddress && billingAddress && paymentMethod!.payload) {
        confirmMyOrder();
      } else if (!paymentMethod!.payload) {
        setErrorMessage('Please select payment card and continue.');
      } else {
        setErrorMessage('Please select payment method to continue.');
      }
    } else {
      setErrorMessage('Please select payment method to continue.');
    }
  };

  const getAppliedCoupon = (coupon: Record<string, any>) => {
    setAppliedCoupon(coupon);
  };
  const getPaymentMethod = (payment: Record<string, any>) => {
    setPaymentMethod(payment);
  };

  const getPaymentType = (type: string) => {
    setPaymentType(type);
  };

  // const onWalletPayment = (payment: Record<string, any>) => {
  //   setPaymentMethod(payment);
  //   if (deliveryAddress && billingAddress && payment.payload) {
  //     const coupon = JSON.parse(localStorage.getItem('coupon')!);
  //     confirmMyOrder(payment.payload, payment.type, coupon);
  //   } else {
  //     setErrorMessage('Please select payment method to continue.');
  //   }
  // };

  const confirmMyOrder = async (pmId?: string, type?: string, coupon?: Record<string, any>) => {
    setIsOrderProcessing(true);
    setcheckOutProcessStep({ setp: 4, stepLable: 'Processing Order' });
    const deliveryAddressData = {
      addressLine1:
        deliveryAddress?.fullAddress +
        ', ' +
        deliveryAddress?.city +
        ', ' +
        deliveryAddress?.state +
        ', ' +
        deliveryAddress?.zip,
      addressLine2: deliveryAddress?.fullAddress,
      city: deliveryAddress?.city,
      state: deliveryAddress?.state,
      latitude: deliveryAddress?.latitude,
      longitude: deliveryAddress?.longitude,
      pin: deliveryAddress?.zip,
    };
    const billAddressData = {
      addressLine1:
        billingAddress?.fullAddress +
        ', ' +
        billingAddress?.city +
        ', ' +
        billingAddress?.state +
        ', ' +
        billingAddress?.zip,
      addressLine2: billingAddress && billingAddress.fullAddress,
      city: billingAddress && billingAddress.city,
      state: billingAddress && billingAddress.state,
      latitude: billingAddress && billingAddress.latitude,
      longitude: billingAddress && billingAddress.latitude,
      pin: billingAddress && billingAddress.zip,
    };
    const orderId = myCartState.globalOrderId;
    const paymentMethodId = (paymentMethod && paymentMethod?.payload) || pmId;
    const source = (paymentMethod && paymentMethod?.type) || type;

    const businessData = {
      userId: items[0].customData.product.businessId,
      status: 'ASSIGN',
      type: 'business',
      // ...retailerBusiness,
    };
    const assign = await assignTask(orderId, businessData);
    const business = {
      // business: {
      //   ...retailerBusiness,
      //   businessDetails: {
      //     // emailConfig: businessDetails!.emailConfig || '',
      //   },
      // },
      businessId: items[0].customData.product.businessId,
      eventId: myCartState.eventId,
    };
    const deliveryType = 'delivery';
    const couponCode = appliedCoupon ? appliedCoupon : coupon;
    const phoneNumber = contactNumber!.startsWith('+1') ? contactNumber : '+1' + contactNumber;
    if (assign.status === 1) {
      const res = await confirmOrder(
        orderId,
        paymentMethodId!,
        deliveryAddressData,
        billAddressData,
        phoneNumber!,
        source,
        business,
        deliveryType,
        couponCode!,
      );
      if (res.status === 1) {
        localStorage.removeItem('coupon');
        localStorage.removeItem('phoneNumber');
        dispatch(getMyCartData());
        history.push({
          pathname: `/order/${orderId}`,
          state: {
            order: res.result,
          },
        });
      } else {
        setcheckOutProcessStep({ setp: 4, stepLable: 'CONFIRM AND PAY' });
        setIsOrderProcessing(false);
        setErrorMessage(res.error.errorMessage);
      }
    }
  };

  const onOrderError = (err: any) => {
    setErrorOnOrder(err);
    if (err && err.errorCode === 400008) {
      setBackBtnText('Back To Cart');
    } else {
      setBackBtnText('Back To Event');
    }
  };

  return (
    <div className="mycart_page mt-3 mt-md-4 col-12 col-md-10 offset-md-1 p-0">
      <div className={`col-md-12 p-0 pl-md-2 d-flex justify-content-between `} onClick={() => backToEvent()}>
        <div>
          <span
            role="button"
            style={{ alignSelf: 'center', fontSize: 16, background: '#f0c787', borderRadius: 4, fontWeight: 500 }}
            className="p-1 pl-2 pr-2"
          >
            <FaChevronLeft title="Back" color="#000" className="mb-1 pr-1" />{' '}
            <span style={{ color: '#000000' }}>{backBtnText}</span>
          </span>
        </div>
      </div>
      <div className="col-md-12 row m-0 p-0 ">
        <div className={`p-0 pl-md-2 pr-md-2 col-12 ${items.length > 0 ? 'col-md-8 __border_right' : ''} `}>
          {errorMessage && errorMessage.length > 0 && (
            <div
              className="d-flex justify-content-between card__payment mt-4 small"
              style={{ background: '#fff1ec', border: '1px solid #ff5722' }}
            >
              <div style={{ color: '#000000' }}>
                <FaInfoCircle size={15} />
                &nbsp; {errorMessage}
              </div>
            </div>
          )}

          <AvailableOffers coupons={coupons} />

          {checkOutProcessStep.setp === 1 && (
            <>
              <div className={`p-0 col-12 page-title with-btn d-flex justify-content-between align-items-end  mt-2 `}>
                {/* <div className="section-title"> */}
                <h3 className="m-0 mt-2">My Cart</h3>
              </div>
              <CartItems getOrderCost={(v) => getOrderCost(v)} />
            </>
          )}
          {checkOutProcessStep.setp === 2 && (
            <>
              <div className={`p-0 col-12 page-title with-btn d-flex justify-content-between align-items-end  mt-2 `}>
                {/* <div className="section-title"> */}
                <h3 className="m-0 mt-2">Delivery</h3>
              </div>
              <DeliveryInfo
                getOrderCost={(v) => getOrderCost(v)}
                // onStorePickup={(value) => setStorePickup(value)}
                businessDetails={businessDetails!}
                business={retailerBusiness!}
                getDeliveryAddress={(userAddress: Record<string, any> | undefined) => {
                  const contactDetails = userAddress?.contactDetails;
                  const deliveryAddress = userAddress?.address;
                  setDeliveryAddress(deliveryAddress);

                  if (contactDetails) {
                    setContactNumber(contactDetails.contactNumber);
                  }
                }}
                getOrderError={(error) => onOrderError(error)}
                // isStorePickup={isStorePickup}
              />
            </>
          )}
          {checkOutProcessStep.setp === 3 && (
            <>
              <div className={`p-0 col-12 page-title with-btn d-flex justify-content-between align-items-end  mt-2`}>
                {/* <div className="section-title"> */}
                <h3 className="m-0 mt-2">Delivery</h3>
              </div>
              <BillingInfo
                getBillingAddress={(userBillingAddress) => {
                  const contactDetails = userBillingAddress?.contactDetails;
                  const billingAddress = userBillingAddress?.address;

                  setBillingAddress(billingAddress);
                  if (contactDetails) {
                    setContactNumber(contactDetails.contactNumber);
                  }
                }}
                // isStorePickup={isStorePickup}
                deliveryAddress={deliveryAddress!}
              />
            </>
          )}
          {checkOutProcessStep.setp === 4 && (
            <>
              <div className={`p-0 col-12 page-title with-btn d-flex justify-content-between align-items-end  mt-2`}>
                {/* <div className="section-title"> */}
                <h3 className="m-0 mt-2 ">Payment</h3>
              </div>

              <PaymentInformation
                billingAddress={billingAddress!}
                deliveryAddress={deliveryAddress!}
                business={retailerBusiness!}
                businessDetails={businessDetails!}
                orderCost={orderCost!}
                checkOutProcessStep={checkOutProcessStep}
                appliedCoupon={appliedCoupon!}
                contactNumber={contactNumber!}
                getOrderCost={(v) => getOrderCost(v)}
                getAppliedCoupon={(coupons) => getAppliedCoupon(coupons)}
                getPaymentMethod={(payment) => getPaymentMethod(payment)}
                getpaymentType={(type) => getPaymentType(type)}
                // onWalletPayment={(payment) => onWalletPayment(payment)}
                coupons={coupons}
              />
            </>
          )}
        </div>
        {items.length > 0 && (
          <div className="p-0 pl-md-2 pr-md-2 col-12 col-md-4">
            <div className="d-none d-md-block">
              <CostInfo {...orderCost!} />
            </div>
            <div className={`mt-3 mb-3 cartButtons button-bar`}>
              <button
                disabled={isOrderProcessing || (isErrorOnOrder && isErrorOnOrder?.isError)}
                onClick={() => checkOutProcess(checkOutProcessStep)}
                id="confirmOrder"
                className={`btn btnPrimary`}
                style={{ cursor: 'pointer', letterSpacing: 1 }}
              >
                {checkOutProcessStep.stepLable}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
