import { CSSProperties } from 'react';
import availableOffersIcon from 'src/assets/icons/availableOffers.svg';

export const AvailableOffers = (props: any) => {
  const cartTitleStyle: CSSProperties = {
    fontFamily: 'sf_pro_displaysemibold',
    fontSize: '14px',
    color: '#b25661',
    textTransform: 'uppercase',
    margin: '0',
    listStylePosition: 'inside',
    paddingBottom: 5,
  };
  return (
    <div className={'border mt-4'}>
      <div className={``}>
        <div className={`p-2 p-md-3`}>
          <div className="row no-gutters">
            <div className="col-11 align-self-end d-flex">
              <img src={availableOffersIcon} alt="availableOffersIcon" />
              &nbsp;&nbsp;
              <h5 className={'mb-0'}>Available Offers</h5>
            </div>
            <div className="row no-gutters">
              <div className={`d-flex mt-md-3  flex-column`}>
                <ul className="list-group pl-2">
                  {props.coupons.map((item: any, index: number) => {
                    return (
                      <li key={index + '__coupons'} style={{ ...cartTitleStyle }}>
                        {item.discount.description}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
